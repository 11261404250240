<template>
  <div class="">
    <div v-if="auth.role == 'root'" class="search-box mb-2 d-inline-block">
      <div class="position-relative">
        <select
          class="form-control"
          v-model="lecturer_id"
          @change="fetchData"
          :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
        >
          <option value="">{{ $t("view.lecturer_name") }}</option>
          <option
            v-for="(lecturer, idx) in lecturers"
            :key="idx"
            :value="lecturer.id"
          >
            {{ lecturer[$i18n.locale]["title"] }}
          </option>
        </select>
      </div>
    </div>

    <Loading v-if="pgLoading"> </Loading>

    <div v-if="!pgLoading" class="row">
      <div class="col-md-3" v-for="(item, idx) in items" :key="idx">
        <div class="card mini-stats-wid">
          <div class="card-body">
            <div class="media">
              <div class="media-body">
                <div style="text-align: left">
                  <!-- <button 
                                      type="button"
                                      @click="editItem(item.encrypt_id)"
                                      class="btn btn-light btn-rounded btn-sm"
                                      style="width: 32px;height:32px;padding:0;margin-left: 5px">
                                      <i class="mdi mdi-pencil font-size-16"></i>
                                  </button>
                                  <button 
                                      type="button"
                                      @click="item.loading=true; deleteItem(item.encrypt_id)"
                                      class="btn btn-light btn-rounded btn-sm"
                                      style="width: 32px;height:32px;padding:0;display:none">
                                      <i class="mdi mdi-delete font-size-16"></i>
                                  </button> -->
                </div>
                <h4
                  class="text-muted fw-medium mt-1 text-center"
                  :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                >
                  {{ $i18n.locale == "ar" ? item.ar.title : item.en.title }}
                </h4>
                <p class="text-center">
                  <!-- <router-link :to="'/settings/show?parent_id='+item.encrypt_id+'&slug='+item.title"
                                      class="btn btn-dark btn-rounded mt-2"
                                      :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : ''">
                                      {{ $t('app.edit') }}
                                  </router-link> -->
                  <button
                    type="button"
                    @click="editItem(item.encrypt_id, item.slug)"
                    class="btn btn-dark btn-rounded mt-2"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                    "
                  >
                    {{ $t("app.edit") }}
                    <i class="mdi mdi-pencil font-size-16"></i>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";

export default {
  name: "Cards",
  props: ["refs", "slug"],
  components: {
    Loading: () => import("@/components/Loading.vue"),
  },
  data() {
    return {
      auth: {
        role: "",
        access_token: "",
      },
      permissions: {
        add: false,
      },
      pgLoading: true,
      items: [],

      lecturerLoading: false,
      lecturer_id: "",
      lecturers: [],
    };
  },
  watch: {
    //
  },
  mounted() {},
  created() {
    // role && accessToken
    if (localStorage.getItem("role")) {
      this.auth.role = localStorage.getItem("role");
    }
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }
    if (this.slug == "financialdues") {
      console.log("sa7");
    }
    // fetchData
    this.fetchData();

    if (this.auth.role == "root") {
      this.fetchLecturers();
    }
  },
  methods: {
    fetchData(loading = false) {
      if (loading) {
        this.pgLoading = true;
      }
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };

      const options = {
        url: window.baseURL + "/" + this.$props.refs,
        method: "GET",
        data: {},
        params: {
          lecturer_id: this.lecturer_id,
        },
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;
          this.items = res.data.data.rows;
          this.permissions.add = res.data.data.permissions.add;
          this.$emit("hasAddNewPermission", this.permissions.add);
        })
        .catch((err) => {
          //403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "ti-alert",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        })
        .finally(() => {});
    },

    editItem(id, slug) {
      if (
        slug == "onlinecourses" ||
        slug == "offlinecourses" ||
        slug == "books" ||
        slug == "diplomats"
      ) {
        this.$router.push({
          name: "edit-status-" + this.$props.refs,
          params: { id: id },
          query: { parent_id: this.parent_id, slug: this.slug },
        });
      } else if (slug == "faqs" || slug == "emergencyconsultations") {
        this.$router.push({
          name: "edit-price-" + this.$props.refs,
          params: { id: id },
          query: { parent_id: this.parent_id, slug: this.slug },
        });
      } else if (slug == "meetingconventions") {
        this.$router.push({
          name: "edit-meeting-" + this.$props.refs,
          params: { id: id },
          query: { parent_id: this.parent_id, slug: this.slug },
        });
      } else {
        this.$router.push({
          name: "edit-" + this.$props.refs,
          params: { id: id },
          query: { parent_id: this.parent_id, slug: this.slug },
        });
      }
    },

    deleteItem(id) {
      if (confirm("Are you sure?")) {
        this.axios.defaults.headers.common = {
          "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
          Authorization: `Bearer ` + this.auth.access_token,
        };
        const options = {
          url: window.baseURL + "/" + this.$props.refs + "/" + id,
          method: "DELETE",
          data: {},
        };
        this.axios(options)
          .then(() => {
            this.fetchData(true);
            iziToast.success({
              icon: "bx bx-wink-smile",
              title: "",
              message: "تم الحذف بنجاح",
            });
          })
          .catch((err) => {
            this.fetchData(true);
            iziToast.error({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          })
          .finally(() => {});
      } else {
        // in case pressed cancel
        this.fetchData(true);
      }
    },

    fetchLecturers() {
      this.lecturerLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/lecturers",
        method: "GET",
        data: {},
        params: {
          status: "active",
          paginate: 100,
        },
      };
      this.axios(options)
        .then((res) => {
          this.lecturerLoading = false;
          this.lecturers = res.data.data.rows;
        })
        .catch((err) => {
          this.lecturerLoading = false;
          iziToast.warning({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>